<template>
  <div class="modal-box">
    <div class="modal-header">
      <div class="confirm-title">{{ title }}</div>
    </div>
    <div class="modal-body">
      <div class="">
        Gán tài xế
        <strong>{{ this.supplier_id }} - {{ this.supplier_name }}</strong> vào đơn hàng mã
        <strong>{{ this.order_id }}</strong
        >?
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn-cancel" v-on:click="cancel">Hủy</button>
      <button class="btn-confirm btn-confirm__text" v-on:click="confirmAssign">Đồng ý</button>
    </div>
  </div>
</template>

<script>
import success_icon from "@/assets/supplier-listing/success-icon.png";
import { adminPushNotification } from "@/pages/SupplierListing/services/adminService";
import { adminAssignOrderV3 } from "@/pages/SupplierListing/services/orderService";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalNew",
  data() {
    return {
      title: "Xác nhận gán đơn",
      order_assigned: null,
      assign_error: null,
      show_toast: { type: "", enable: false },
      image: success_icon,
    };
  },
  computed: {
    ...mapState("supplierList", [
      "supplier_token",
      "order_id",
      "admin_token",
      "supplier_id",
      "supplier_name",
      "master_supplier_id",
      "order_service",
      "user_id",
    ]),
  },
  methods: {
    ...mapActions("supplierList", ["actionSetShowConfirmModal", "actionSetOrderAssigned", "actionSetShowToast"]),
    cancel() {
      this.actionSetShowConfirmModal({ value: false });
    },
    async confirmAssign() {
      try {
        /*if (this.master_supplier_id === this.supplier_id)
          return (this.show_toast = {
            type: "failed",
            message: "Không thể gán đơn hàng cho chính mình",
            enable: true,
          });*/

        const { status, message } = await adminAssignOrderV3(this.admin_token, this.order_id, this.supplier_id);
        // console.log({ status });
        if (status === 200) {
          // console.log("success");
          this.actionSetShowConfirmModal({ value: false });
          // this.order_assigned = true;
          // this.actionSetOrderAssigned({ value: this.order_assigned });
          // this.assign_error = false;
          this.show_toast = { type: "success", message: null, enable: true };
          this.actionSetShowConfirmModal({ value: false });
          return setTimeout(() => {
            adminPushNotification(
              this.master_supplier_id,
              this.order_id,
              this.order_service,
              this.user_id,
              this.supplier_id,
              this.supplier_name
            );
            this.postMessage({
              name: this.EVENT_CLOSE,
            });
          }, 5000);
        } else {
          // console.log("failed");
          // this.order_assigned = false;
          // this.assign_error = true;
          this.show_toast = { type: "failed", message, enable: true };
        }
      } catch (err) {
        console.log({ err });
        this.show_toast = {
          type: "failed",
          message: "Đơn hàng không thể gán. Đã có lỗi xảy ra.",
          enable: true,
        };
        return setTimeout(() => {
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 5000);
      } finally {
        this.actionSetShowToast({ value: this.show_toast });
        setTimeout(() => {
          this.show_toast.enable = false;
          this.actionSetShowToast({ value: this.show_toast });
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-box {
  font-family: "SVN-Gilroy";

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  height: auto;
  padding: 16px;
  z-index: 20;

  background: #ffffff;
  border-radius: 4px;

  .modal-header {
    .confirm-title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 8px 0;

      color: #343a3f;
    }
  }

  .modal-body {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    line-height: 22px;
    margin: 8px 0;

    color: #697077;
  }

  .modal-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;

    .btn {
      &-confirm {
        border: 0;
        border-radius: 2px;

        height: 48px;
        width: 140px;

        background: #3a86ff;

        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;

          color: #ffffff;
        }
      }

      &-cancel {
        border: 0;
        border-radius: 2px;

        height: 48px;
        width: 140px;

        background: #f2f2f2;

        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;

          color: #142246;
        }
      }
    }
  }
}
</style>
